html body {
  background-color: #fafafa;
  --primary-color: #2490EF;
  --border-primary: #2490EF;
  --btn-primary: #2490EF;
  --primary: #2490EF;
}

.form-control {
  background: #ffffff !important;
  border: 1px solid #bbb !important;
  box-shadow: inset 1px 1px 7px -4px rgba(0, 0, 0, 0.44) !important;
  height: 40px !important;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.form-control:hover {
  border-color: #999999;
}
.form-control:focus {
  background: #ffffff !important;
  border-color: var(--primary) !important;
  box-shadow: inset 1px 1px 7px -4px rgba(31, 128, 255, 0.59), 0 4px 24px 0 rgba(18, 145, 255, 0.1) !important;
}

.btn-light, .btn-default {
  background: #ffffff !important;
  border: 1px solid #dddddd !important;
  color: #1f272e !important;
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.1490196078), 0 1px 1px rgba(0, 0, 0, 0.0745098039) !important;
  transition: all 0.2s ease-in-out;
}
.btn-light:hover, .btn-default:hover {
  background: #f5f5f5 !important;
  border: 1px solid #aaaaaa !important;
}

.btn-login, .btn-forgot {
  box-shadow: none !important;
  height: 40px;
  font-weight: 600;
}

.btn-primary:not(:disabled):not(.disabled):active, div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: #0a7ce1 !important;
  border-color: #0a7ce1 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.for-login .page-card .page-card-body .field-icon, .for-forgot .page-card .page-card-body .field-icon, .for-login-with-email-link .page-card .page-card-body .field-icon, .for-signup .page-card .page-card-body .field-icon, .for-email-login .page-card .page-card-body .field-icon {
  left: 12px !important;
  top: 12px !important;
}

.for-login .page-card .page-card-body .email-field .toggle-password, .for-login .page-card .page-card-body .password-field .toggle-password, .for-forgot .page-card .page-card-body .email-field .toggle-password, .for-forgot .page-card .page-card-body .password-field .toggle-password, .for-login-with-email-link .page-card .page-card-body .email-field .toggle-password, .for-login-with-email-link .page-card .page-card-body .password-field .toggle-password, .for-signup .page-card .page-card-body .email-field .toggle-password, .for-signup .page-card .page-card-body .password-field .toggle-password, .for-email-login .page-card .page-card-body .email-field .toggle-password, .for-email-login .page-card .page-card-body .password-field .toggle-password {
  right: 12px !important;
  top: 10px !important;
  font-size: 14px;
}

.for-login, .for-forgot, .for-signup, .for-login-with-email-link {
  margin: 0 auto;
  display: none;
  font-size: 14px;
  min-width: 100%;
}
@media (min-width: 600px) {
  .for-login, .for-forgot, .for-signup, .for-login-with-email-link {
    min-width: 380px;
  }
}
.for-login .page-card-head, .for-forgot .page-card-head, .for-signup .page-card-head, .for-login-with-email-link .page-card-head {
  text-align: center;
}
.for-login .page-card-head > img, .for-forgot .page-card-head > img, .for-signup .page-card-head > img, .for-login-with-email-link .page-card-head > img {
  max-width: 100%;
  height: 80px;
}
.for-login .page-card-head > h4, .for-forgot .page-card-head > h4, .for-signup .page-card-head > h4, .for-login-with-email-link .page-card-head > h4 {
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#page-login {
  height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}